<template>
  <div>
    <SideBar :active="drawer" />
    <v-row no-gutters>
      <v-app-bar id="navbar" app class="elevation-0" clipped-left color="white">
        <v-col cols="auto">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer">
            <v-icon class="primary--text"> fa-solid fa-bars </v-icon>
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="2" md="4" sm="4" lg="4" xl="4">
          <Logo width="180" />
        </v-col>
        <v-col cols="4" v-if="!$vuetify.breakpoint.xs">
          <v-text-field
            prepend-icon="fa-solid fa-magnifying-glass"
            outlined
            hide-details
            dense
            clearable
            v-model="searchField"
            @keydown.enter="onSearch"
            @click:clear="cleanField()"
            :label="$t('components.layout.navbar.search')"
            :placeholder="$t('components.layout.navbar.search_placeholder')"
          />
        </v-col>
        <v-col cols v-if="!$vuetify.breakpoint.xs">
          <Logo id="log_company" width="180" />
        </v-col>
      </v-app-bar>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    SideBar: () => import(/* webpackChunkName: "SideBar" */ "@/components/layout/SideBar.vue"),
  },
  data: () => ({
    menu: {},
    searchField: "",
    drawer: true,
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
      token: "auth/token",
      search: "search/search",
    }),
  },
  watch: {
    search(newValue) {
      if (!newValue) {
        this.cleanField();
      }
    },
  },
  mounted() {
    if (this.$route.query.search) {
      this.showSearch({ search: this.$route.query.search });
      this.searchField = this.search
    }
  },
  methods: {
    ...mapActions("search", ["showSearch"]),

    onSearch() {
      this.showSearch({ search: this.searchField });
    },

    cleanField() {
      this.showSearch({ search: "" });
      this.$route.query.search = ''
    },
  },
};
</script>
<style scoped>
#logo {
  margin-top: 15px;
}

#log_company {
  float: right;
  margin-top: 0.5em;
}

.v-image {
  margin: 0 auto !important;
}
</style>
